import { AboutUsStyle } from './AboutUs.styles';
import { Link } from 'react-router-dom';
import useFetchTotalParticles from '../../hooks/useFetchTotalParticles';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import Latex from 'react-latex-next';
import database_structure from '../../assets/images/database_structure.png'
import 'katex/dist/katex.min.css'; // Import the CSS for KaTeX styling

/**
 * Helper function to generate a valid HTML id for sub-items (no spaces, etc.).
 */
function makeSubId(catKey, choiceName) {
  	return `${catKey}-${choiceName.replace(/[^\w-]+/g, '-')}`;
}

/**
 * AboutUs Component: Provides information about the VolcAshDB project, including
 * details about the dataset, publications, contributors, and contact information.
 * 
 * @returns {JSX.Element} - The rendered AboutUs component.
 */
const AboutUs = () => {
    // Styles for the AboutUs component
    const classes = AboutUsStyle();

    // Fetch total number of particles from the custom hook
    const { totalParticles } = useFetchTotalParticles();
	const formattedParticles = totalParticles.toLocaleString();

    // State variables for toggling sections
    const [siteGuideOpen, setSiteGuideOpen] = useState(false);
    const [latestPublicationsOpen, setLatestPublicationsOpen] = useState(false);
    const [contributorsOpen, setContributorsOpen] = useState(false);
    const [contactUsOpen, setContactUsOpen] = useState(false);
	const [databaseDesignOpen, setDatabaseDesignOpen] = useState(false);
	const [experimentalSamplesOpen, setExperimentalSamplesOpen] = useState(false);

	// Toggle functions
    const toggleSiteGuide = () => setSiteGuideOpen(!siteGuideOpen);
    const toggleLatestPublications = () => setLatestPublicationsOpen(!latestPublicationsOpen);
    const toggleContributors = () => setContributorsOpen(!contributorsOpen);
    const toggleContactUs = () => setContactUsOpen(!contactUsOpen);
	const toggleDatabaseDesign = () => setDatabaseDesignOpen(!databaseDesignOpen);
	const toggleExperimentalSamples = () => setExperimentalSamplesOpen(!experimentalSamplesOpen);

	// Each category in the glossary has its own open/close state
	const [openItems, setOpenItems] = useState({});
	const toggleCategory = (e, catKey) => {
		e.preventDefault();
		setOpenItems((prev) => ({
			...prev,
			[catKey]: !prev[catKey],
		}));
	};

	/**
	 * Glossary data
	 * 
	 * You can add or remove categories as needed. Each category
	 * has a label and an array of choices. Every choice has a
	 * "name" and a "definition".
	 */
	const glossaryData = {
		eruptiveActivityType: {
			label: 'Eruptive Activity Type',
			choices: [
				{
					name: 'Phreatic',
					definition: (
						<span>
							Phreatic eruptions are steam-driven, often caused by heating of groundwater{' '}
							(<a
								href="https://en.wikipedia.org/wiki/Phreatic_eruption"
								target="_blank"
								rel="noopener noreferrer"
							>ref</a>).
						</span>
					),
				},
				{
					name: 'Dome explosion',
					definition: (
						<span>
							Dome explosions are usually triggered by gravitational instabilities or gas
							pressurization{' '}
							(<a
								href="https://en.wikipedia.org/wiki/Lava_dome#Dome_dynamics"
								target="_blank"
								rel="noopener noreferrer"
							>ref</a>).
						</span>
					),
				},
				{
					name: 'Lava fountaining',
					definition: (
						<span>
							Lava fountaining is the ejection of molten lava in a jet-like spray{' '}
							(<a
								href="https://volcanoes.usgs.gov/vsc/glossary/lava_fountain.html"
								target="_blank"
								rel="noopener noreferrer"
							>ref</a>).
						</span>
					),
				},
				{
					name: 'Subplinian',
					definition: (
						<span>
							Subplinian eruptions are explosive, sustained, and with relatively high columns but
							less (&lt; 20 km) than Plinian eruptions{' '}
							(<a
								href="https://www.nps.gov/articles/000/sub-plinian-eruptions.htm#:~:text=Sub%2DPlinian%20eruptions%20produce%20higher,and%20are%20unsteady%20but%20sustained."
								target="_blank"
								rel="noopener noreferrer"
							>ref</a>).
						</span>
					),
				},
				{
					name: 'Plinian',
					definition: (
						<span>
							Plinian eruptions are extremely explosive, more intense than Subplinian and have very
							high eruption columns, often over 20 km{' '}
							(<a
								href="https://www.nps.gov/articles/000/plinian-eruptions.htm?utm_source=article&utm_medium=website&utm_campaign=experience_more&utm_content=large"
								target="_blank"
								rel="noopener noreferrer"
							>ref</a>).
						</span>
					),
				}
			],
		},
		grainSize: {
			label: 'Grain Size',
			choices: [
				{
					name: 'Phi scale',
					definition: (
						<div style={{"paddingLeft": "10px"}}>
							<p>
								The Krumbein phi scale defines grain-size fractions as a logarithmic scale{' '}
								(<a
									href="https://en.wikipedia.org/wiki/Grain_size#Krumbein_phi_scale"
									target="_blank"
									rel="noopener noreferrer"
								>ref</a>).
							</p>
							<p>
								The formula is given by:
								<Latex>{`$\\varphi = -\\log_2{\\left(\\frac{D}{D_0}\\right)}$`}</Latex>
							</p>
							<p>
								Where:
								<ul>
									<li>
										<Latex>{`$\\varphi$`}</Latex> is the Krumbein phi scale.
									</li>
									<li>
										<Latex>{`$D$`}</Latex> is the diameter of the particle or grain in millimeters.
									</li>
									<li>
										<Latex>{`$D_0$`}</Latex> is a reference diameter, equal to 1 mm.
									</li>
								</ul>
							</p>
						</div>
					)
				},
				{
					name: 'Mesh scale',
					definition: (
						<div style={{"paddingLeft": "10px"}}>
							<p>
								The Mesh scale is a classification system used to describe particle size based on the number 
								of openings per linear inch in a sieve. It is commonly used in sedimentology and
								material sciences{' '}            
								(<a
									href="https://en.wikipedia.org/wiki/Mesh_(scale)"
									target="_blank"
									rel="noopener noreferrer"
								>ref</a>).
							</p>
							<p>
								The mesh number (n) corresponds to the number of openings per inch in the sieve:
								<Latex>{`$\\space D = \\frac{25.4}{n}$`}</Latex>
							</p>
							<p>
								Where:
								<ul>
									<li>
										<Latex>{`$D$`}</Latex> is the particle diameter in millimeters.
									</li>
									<li>
										<Latex>{`$n$`}</Latex> is the mesh size (openings per inch).
									</li>
									<li>25.4 is the conversion factor from inches to millimeters.</li>
								</ul>
							</p>
							<p>
								For example, a mesh size of 60 corresponds to 0.25 mm,
								while mesh 200 corresponds to 0.074 mm.
							</p>

							{/* ----------------- TABLE OF EQUIVALENCES AFTER MESH SCALE ---------------- */}
							<hr/>
							<h3>Equivalences of our grain-size fractions</h3>
							<p>
								VolcAshDB uses specific tags to filter the particles into grain-size fractions as follows:
							</p>
							<table border="1" style={{borderCollapse: 'collapse', margin: 'auto'}}>
								<thead>
									<tr>
										<th className={classes.thStyle}>Fraction Tag</th>
										<th className={classes.thStyle}>Meaning</th>
										<th className={classes.thStyle}>Size in microns (µm)</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className={classes.tdStyle}>morephi0</td>
										<td className={classes.tdStyle}>Particles &gt; 0 phi</td>
										<td className={classes.tdStyle}> &gt; 1000 µm</td>
									</tr>
									<tr>
										<td className={classes.tdStyle}>phi0phi1</td>
										<td className={classes.tdStyle}>Particles between 0–1 phi</td>
										<td className={classes.tdStyle}>1000 – 500 µm</td>
									</tr>
									<tr>
										<td className={classes.tdStyle}>phi1phi2</td>
										<td className={classes.tdStyle}>Particles between 1–2 phi</td>
										<td className={classes.tdStyle}>500 – 250 µm</td>
									</tr>
									<tr>
										<td className={classes.tdStyle}>mesh60</td>
										<td className={classes.tdStyle}>Particles passing 60 mesh</td>
										<td className={classes.tdStyle}>≈ 250 µm</td>
									</tr>
									<tr>
										<td className={classes.tdStyle}>mesh120</td>
										<td className={classes.tdStyle}>Particles passing 120 mesh</td>
										<td className={classes.tdStyle}>≈ 125 µm</td>
									</tr>
								</tbody>
							</table>
						</div>
					)
				}
			],
		},
		mainType: {
			label: 'Main Type',
			choices: [
				{
					name: 'Free Crystal',
					definition: 'Free crystals often exhibit planar structures (e.g., twinning) and well-faceted crystal habit. We typically find plagioclase and pyroxene, minor amphibole, and rarely native sulfur and olivine.',
				},
				{
					name: 'Altered material',
					definition: 'Altered particles typically have granular texture or form aggregates that are white, or yellowish to reddish. When weathered, these typically exhibit a loss in shine (dull luster), round edges, and modifications of the original groundmass.',
				},
				{
					name: 'Lithic',
					definition: 'Lithic particles are typically dull, dark, with sub-angular to rounded edges, and contain limited signs of weathering or hydrothermal alteration.',
				},
				{
					name: 'Juvenile',
					definition: 'Juvenile particles exhibit shiny gloss, sharp edges, smooth-skinned surface, and lack of weathering and alteration features.',
				}
			]
		},
		/** NEW SHAPES CATEGORY */
		shapes: {
			label: 'Shapes',
			choices: [
				{
					name: 'blocky',
					definition: 'Relatively equant particles with perpendicular to sub-perpendicular edges.'
				},
				{
					name: 'fluidal',
					definition: 'Smooth-surfaced particles with curved, rounded walls.'
				},
				{
					name: 'microtubular',
					definition: 'Particles characterized by elongated hollows (microtubes) throughout.'
				},
				{
					name: 'spongy',
					definition: 'Abundant, relatively small vesicles (e.g., 20 µm diameter) giving a sponge-like appearance.'
				},
				{
					name: 'highly vesicular',
					definition: 'Particles with less numerous but larger vesicles (e.g., ~150 µm diameter).'
				},
				{
					name: 'pumice',
					definition: 'Golden particles whose groundmass contains abundant, <10 µm vesicles throughout.'
				},
				{
					name: 'aggregate',
					definition: 'Mixture of coated crystals, glass and/or hydrothermal material stuck together.'
				},
			],
		},
	};


	return (
		<div className={classes.AboutUsContainer}>
			<h1>About VolcAshDB</h1>
			<p>        
				We created the Volcanic Ash DataBase (VolcAshDB) to advance towards a more standardized approach in volcanic ash particle classification. 
				The database hosts over {formattedParticles} high-resolution images of ash particles from diverse magma compositions and eruptive styles. 
				Each particle has been classified into one of four groups: altered material, free crystal, juvenile and lithic. In addition, particles have 
				been individually characterized through the extraction of features sensitive to the particle shape, texture, and color. VolcAshDB allows free 
				access for users to filter and browse through ash particle images, visualize the particle features' distributions, and download images along 
				with their feature values and metadata. This platform can be useful for comparative studies and offers a dataset suitable for training 
				Machine Learning models to automatically classify ash particles.
			</p>
			
			<p style={{fontSize: '12px'}}>
				Version 0.1 of Dataset published 2024 in Centre de données de l'Institut de Physique du Globe de Paris &nbsp;
				<a href="https://doi.org/10.18715/ipgp.2024.lx32oxw9" target="_blank" rel="noopener noreferrer">
					https://doi.org/10.18715/ipgp.2024.lx32oxw9
				</a>
			</p>

			{/* ======== DATABASE DESIGN (COLLAPSIBLE) ======== */}
			<h2 onClick={toggleDatabaseDesign} style={{ cursor: 'pointer' }}>
				Database Design
				<IconButton color="inherit">
					{databaseDesignOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</h2>

			{databaseDesignOpen && (
				<div>
					<p>
						The VolcAshDB database is structured using MongoDB, a NoSQL database that organizes data into flexible,
						schema-less collections. Below is a schematic representation of the database structure and a list of the 
						fields in each collection:
					</p>

					<div style={{ textAlign: "center" }}>
						<img 
							src={database_structure}
							alt="VolcAshDB Database Structure"
							className={classes.imageStyle} 
						/>
					</div>

					{/* =========== MAIN TABLE (VOLCANOES, ERUPTIONS, ETC.) =========== */}
					<div className={classes.tableContainer}>
						<table border="1" className={classes.tableStyle}>
							<caption className={classes.captionStyle}>
								O means Optional. <br /> M means Mandatory.
							</caption>
							<thead>
								<tr>
									<th className={classes.thStyle}>Field</th>
									<th className={classes.thStyle}>Type</th>
									<th className={classes.thStyle}>Optionality</th>
									<th className={classes.thStyle}>Description</th>
								</tr>
							</thead>
							<tbody>
								{/* Volcanoes Collection */}
								<tr>
									<td className={classes.tdStyle}>
										<em>Volcanoes Collection (source Smithsonian database)</em>
									</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>_id</td>
									<td className={classes.tdStyle}>ObjectId</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Document identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_name</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano name</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_num</td>
									<td className={classes.tdStyle}>Int(32)</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_slat</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano location (latitude)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_slon</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano location (longitude)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_status</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Status of the volcano</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_type</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Type of volcano</td>
								</tr>

								{/* Eruptions Collection */}
								<tr>
									<td className={classes.tdStyle}>
										<em>Eruptions Collection (source Smithsonian database)</em>
									</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>_id</td>
									<td className={classes.tdStyle}>ObjectId</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Document identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_num</td>
									<td className={classes.tdStyle}>Int(32)</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_name</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano name</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>ed_stime</td>
									<td className={classes.tdStyle}>Date</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Eruption start date</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>ed_etime</td>
									<td className={classes.tdStyle}>Date</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Eruption end date</td>
								</tr>

								{/* Ash-Forming Events Collection */}
								<tr>
									<td className={classes.tdStyle}>
										<em>Ash-Forming Events Collection</em>
									</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>_id</td>
									<td className={classes.tdStyle}>ObjectId</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Document identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>afe_code</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Ash forming event identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>volc_num</td>
									<td className={classes.tdStyle}>Int(32)</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Volcano identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>afe_date</td>
									<td className={classes.tdStyle}>Date</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Ash forming event date</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>eruptive_style</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Description of eruption style</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>afe_lat</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Ash forming event location (latitude)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>afe_lon</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Ash forming event location (longitude)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>plume_col</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Plume color</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>max_plume_height</td>
									<td className={classes.tdStyle}>Int(32)</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Maximum recorded plume height</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>fumarolic_activity</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Observed fumarolic activity intensity</td>
								</tr>

								{/* Samples Collection */}
								<tr>
									<td className={classes.tdStyle}>
										<em>Samples Collection</em>
									</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>_id</td>
									<td className={classes.tdStyle}>ObjectId</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Document identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>sample_stime</td>
									<td className={classes.tdStyle}>Date</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Sampling date</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>sample_lat</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Sampling coordinate latitude</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>sample_lon</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Sampling coordinate longitude</td>
								</tr>
								
								{/* Particles Collection */}
								<tr>
									<td className={classes.tdStyle}>
										<em>Particles Collection</em>
									</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>_id</td>
									<td className={classes.tdStyle}>ObjectId</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Document identifier</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>imgURL</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Filename of the particle's image</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>main_type</td>
									<td className={classes.tdStyle}>Object</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Main type of the particle</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>sub_type</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Sub type of the particle</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>color</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Color of the particle</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>luster</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Luster of the particle</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>shape</td>
									<td className={classes.tdStyle}>String</td>
									<td className={classes.tdStyle}>O</td>
									<td className={classes.tdStyle}>Shape of the particle</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>convexity</td>
									<td className={classes.tdStyle}>Double</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Convexity of the particle (shape)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>contrast</td>
									<td className={classes.tdStyle}>Double</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Contrast of the particle (texture)</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>hue_mean</td>
									<td className={classes.tdStyle}>Double</td>
									<td className={classes.tdStyle}>M</td>
									<td className={classes.tdStyle}>Mean value of hue histogram (color)</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}

			{/* =========== EXPERIMENTAL SAMPLES TABLE =========== */}
			<h2 onClick={toggleExperimentalSamples} style={{ cursor: 'pointer' }}>
				Experimental samples information
				<IconButton color="inherit">
					{databaseDesignOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</h2>

			{experimentalSamplesOpen && (
				<div>
					<p>
						These samples were experimentally modified in the work by <a href="https://link.springer.com/article/10.1007/s00410-012-0839-0">D’Oriano et al. (2012).</a>
					</p>

					{/* =========== EXPERIMENTAL SAMPLES TABLE =========== */}
					<div className={classes.tableContainer}>
						<table border="1" className={classes.tableStyle}>
							<thead>
								<tr>
									<th className={classes.thStyle}>sample_lat</th>
									<th className={classes.thStyle}>sample_lon</th>
									<th className={classes.thStyle}>afe_code</th>
									<th className={classes.thStyle}>lab_procedure[0]</th>
									<th className={classes.thStyle}>lab_procedure[1]</th>
									<th className={classes.thStyle}>temperature_lower_bound</th>
									<th className={classes.thStyle}>temperature_upper_bound</th>
									<th className={classes.thStyle}>oxygen_fugacity</th>
									<th className={classes.thStyle}>experiment_duration</th>
									<th className={classes.thStyle}>sample_code</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-00-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}>VE-00-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-01-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>1260</td>
									<td className={classes.tdStyle}>VE-01-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-02-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>80</td>
									<td className={classes.tdStyle}>VE-02-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-03-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>120</td>
									<td className={classes.tdStyle}>VE-03-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-04-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>120</td>
									<td className={classes.tdStyle}>VE-04-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-05-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>60</td>
									<td className={classes.tdStyle}>VE-05-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-08-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>105</td>
									<td className={classes.tdStyle}>VE-08-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-10-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>100</td>
									<td className={classes.tdStyle}>VE-10-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>40.82</td>
									<td className={classes.tdStyle}>14.43</td>
									<td className={classes.tdStyle}>VE-11-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>140</td>
									<td className={classes.tdStyle}>VE-11-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-00-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}>ET-00-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-02-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>81</td>
									<td className={classes.tdStyle}>ET-02-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-03-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>175</td>
									<td className={classes.tdStyle}>ET-03-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-04-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>976</td>
									<td className={classes.tdStyle}>ET-04-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-05-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>60</td>
									<td className={classes.tdStyle}>ET-05-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-06-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>60</td>
									<td className={classes.tdStyle}>ET-06-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-07-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>103</td>
									<td className={classes.tdStyle}>ET-07-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-08-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>30</td>
									<td className={classes.tdStyle}>ET-08-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-11-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>70</td>
									<td className={classes.tdStyle}>ET-11-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-15-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>70</td>
									<td className={classes.tdStyle}>ET-15-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-16-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>180</td>
									<td className={classes.tdStyle}>ET-16-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-20-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>1260</td>
									<td className={classes.tdStyle}>ET-20-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-29-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>978</td>
									<td className={classes.tdStyle}>ET-29-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>37.75</td>
									<td className={classes.tdStyle}>15</td>
									<td className={classes.tdStyle}>ET-30-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>978</td>
									<td className={classes.tdStyle}>ET-30-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-00-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}></td>
									<td className={classes.tdStyle}>ST-00-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-01-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>660</td>
									<td className={classes.tdStyle}>ST-01-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-02-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>80</td>
									<td className={classes.tdStyle}>ST-02-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-03-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>700</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>1140</td>
									<td className={classes.tdStyle}>ST-03-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-07-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>210</td>
									<td className={classes.tdStyle}>ST-07-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-09-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>1000</td>
									<td className={classes.tdStyle}>low</td>
									<td className={classes.tdStyle}>120</td>
									<td className={classes.tdStyle}>ST-09-EXP_1</td>
								</tr>
								<tr>
									<td className={classes.tdStyle}>38.79</td>
									<td className={classes.tdStyle}>15.21</td>
									<td className={classes.tdStyle}>ST-11-EXP</td>
									<td className={classes.tdStyle}>cleaning</td>
									<td className={classes.tdStyle}>sieving</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>1100</td>
									<td className={classes.tdStyle}>high</td>
									<td className={classes.tdStyle}>100</td>
									<td className={classes.tdStyle}>ST-11-EXP_1</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}

			{/* =========== GLOSSARY (SITE GUIDE) SECTION =========== */}
			<h2 onClick={toggleSiteGuide} style={{ cursor: 'pointer' }}>
				Glossary of terms
				<IconButton color="inherit">
					{siteGuideOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</h2>

			{siteGuideOpen && (
				<div>
					<p>Click a category to view definitions. Expand sub-items using the arrow.</p>
					<ul>
						{Object.entries(glossaryData).map(([catKey, { label, choices }]) => (
							<li key={catKey}>
								<a href={`#${catKey}`}><strong>{label}</strong></a>
								{choices && (
									<>
										<IconButton onClick={(e) => toggleCategory(e, catKey)} size="small">
											{openItems[catKey] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
										</IconButton>
										{openItems[catKey] && (
											<ul>
												{choices.map(({ name }) => (
													<li key={name}>
														<a href={`#${makeSubId(catKey, name)}`}>{name}</a>
													</li>
												))}
											</ul>
										)}
									</>
								)}
							</li>
						))}
					</ul>
					<hr />
					<div id="glossary-definitions">
						{Object.entries(glossaryData).map(([catKey, { label, choices }]) => (
							<div key={catKey} id={catKey}>
								<h3>{label}</h3>
								{choices.map(({ name, definition }) => (
									<div key={name} id={makeSubId(catKey, name)} style={{ marginLeft: '1.5rem' }}>
										<p><strong>{name}</strong>: {definition}</p>
									</div>
								))}
							</div>
						))}
					</div>
				</div>
			)}

			{/* =========== LATEST PUBLICATIONS SECTION =========== */}
            <h2 onClick={toggleLatestPublications} style={{ cursor: 'pointer' }}>
                Latest Publications
                <IconButton color="inherit">
                    {latestPublicationsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </h2>
            {latestPublicationsOpen && (
                <div>
                    <p>
                        Benet, D., Costa, F., Widiwijayanti, C., 2024. Volcanic ash classification through Machine Learning. Geochemistry, Geophysics, Geosystems.
                        <br/>
                        <a href="https://doi.org/10.1029/2023GC011224" target="_blank" rel="noopener noreferrer">
                            https://doi.org/10.1029/2023GC011224
                        </a>
                    </p>

                    <p>
                        Benet, D., Costa, F., Widiwijayanti, C., Pallister, J., Pedreros, G., Allard, P., Humaida, H., Aoki, Y. and Maeno, F., 2024. 
						VolcAshDB: a Volcanic Ash DataBase of classified particle images and features. Bulletin of Volcanology, 86(1), pp.1-30.
                        <br/>
                        <a href="https://doi.org/10.1007/s00445-023-01695-4" target="_blank" rel="noopener noreferrer">
                            https://doi.org/10.1007/s00445-023-01695-4
                        </a>
                    </p>
                </div>
            )}

			{/* =========== CONTRIBUTORS SECTION =========== */}
            <h2 onClick={toggleContributors} style={{ cursor: 'pointer' }}>
                Contributors
                <IconButton color="inherit">
                    {contributorsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </h2>
            {contributorsOpen && (
                <div>
                    <strong>VolcAshDB Team</strong>
                    <ul style={{listStyleType:"none"}}>
                        <strong>Project Principal Investigator</strong>
                        <ul style={{listStyleType:"none"}}>
                            <li>Fidel Costa</li>
                        </ul>     
                        <strong>Coordinators</strong>
                        <ul style={{listStyleType:"none"}}>
                            <li>Damià Benet</li>
                            <li>Kévin Migadel</li>
                        </ul>  
                        <strong>Web developer</strong>
                        <ul style={{listStyleType:"none"}}>
                            <li>Kévin Migadel</li>
                        </ul>   
                        <strong>Data analyst</strong>
                        <ul style={{listStyleType:"none"}}>
                            <li>Damià Benet</li>
                        </ul>           
                    </ul>

                    <strong>Computer services support by SMV</strong>
                    <ul style={{listStyleType:"none"}}>
                        <li>David Waissenbach</li>
                        <li>Michel Le Cocq</li>
                    </ul>

                    <strong>Previous developers</strong>
                    <ul style={{listStyleType:"none"}}>
                        <li>Charles Tran</li>
                        <li>Khai Truong</li>
                    </ul>

                    <strong>Contributors</strong>
                    <ul style={{listStyleType:"none"}}>
                        <li>John Pallister</li>
                        <li>Gabriela Pedredos</li>
                        <li>Patrick Allard</li>
                        <li>Hanik Humaida</li>
                        <li>Yosuke Aoki</li>
                        <li>Fukashi Maeno</li>
                        <li>Daniel W. J. Lee</li>
                        <li>Claudia D'Oriano</li>
                        <li>Massimo Pompilio</li>
                        <li>Dini Nurfiani</li>
                        <li>Hamdi Rifai</li>
                        <li>Florian Dugauquier</li>
                    </ul>
                </div>
            )}

			{/* =========== CONTACT US SECTION =========== */}
            <h2 onClick={toggleContactUs} style={{ cursor: 'pointer' }}>
                Contact Us
                <IconButton color="inherit">
                    {contactUsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </h2>
            {contactUsOpen && (
                <div>
                    <p>
                        If you have any questions or would like to get in touch, contact us at:&nbsp;  
                        <a href="mailto:volcashdb@ipgp.fr">volcashdb@ipgp.fr</a>
                    </p>
                </div>
            )}

            {/* =========== FOOTER LINKS =========== */}
            <div className={classes.footer}>
                <Link to='/legal' className={classes.legalInformation}>
                    Legal information
                </Link>
                <Link to='/legal' className={classes.legalInformation}>
                    Privacy policy / Data protection
                </Link>
                <Link to='/legal'>
                    License
                </Link>
            </div>
		</div>
    );
};

export default AboutUs;